import React from 'react'
import { useFormContext } from 'react-hook-form'
import useSessionStorage from '../../hooks/useSessionStorage'
import { initialFields } from '../../consts'

export default function NombrePersona({ label, title, required = true }) {
  const {
    getValues,
    register,
    formState: { errors },
  } = useFormContext()

  const [storageData, setStorageData] = useSessionStorage(
    'app_data',
    initialFields
  )

  const handleChange = () => {
    setStorageData(getValues())
  }

  return (
    <div className="form-field">
      {title && <span>{title}</span>}
      <div className="form-group">
        <label htmlFor="MCCPERSTAG.TOMADOR.Nombre">{label || 'Nombre'}</label>
        <input
          type="text"
          id="MCCPERSTAG.TOMADOR.Nombre"
          className={`form-control${
            errors.MCCPERSTAG?.TOMADOR?.Nombre ? ' hasError' : ''
          }`}
          placeholder={label || 'Nombre'}
          {...register('MCCPERSTAG.TOMADOR.Nombre', {
            onChange: handleChange,
            required: required === true ? 'El nombre es obligatorio.' : false,
            pattern: {
              value: /^[a-zA-ZÀ-ÖØ-öø-ÿ ]+$/i,
              message: 'Has introducido caracteres no permitidos.',
            },
            maxLength: {
              value: 150,
              message: 'El nombre es demasiado largo.',
            },
          })}
        />
        {errors.MCCPERSTAG?.TOMADOR.Nombre && (
          <div className="error">
            {errors.MCCPERSTAG.TOMADOR.Nombre.message}
          </div>
        )}
      </div>
    </div>
  )
}
