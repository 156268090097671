import React, { createContext, useContext, useReducer } from 'react'

import storeCartReducer, { initialCart } from '../store/storeCartReducer'

const WooCartContext = createContext()

const WooCartProvider = ({ children }) => {
  return (
    <WooCartContext.Provider value={useReducer(storeCartReducer, initialCart)}>
      {children}
    </WooCartContext.Provider>
  )
}

const useStoreCart = () => useContext(WooCartContext)[0]
const useDispatchCart = () => useContext(WooCartContext)[1]

export { WooCartProvider, useStoreCart, useDispatchCart }
export default WooCartContext
