import React from 'react'
import { useFormContext } from 'react-hook-form'
import useSessionStorage from '../../hooks/useSessionStorage'
import { initialFields } from '../../consts'
import { Information } from '../../assets/icons'

export default function Microchip({ title, label, required = true }) {
  const {
    register,
    getValues,
    formState: { errors },
  } = useFormContext()

  const [storageData, setStorageData] = useSessionStorage(
    'app_data',
    initialFields
  )

  const handleChange = () => {
    setStorageData(getValues())
  }

  const handleHelp = () => {
    const elem = document.getElementById('info-microchip')
    if (elem.classList.contains('d-none')) elem.classList.remove('d-none')
    else elem.classList.add('d-none')
  }

  return (
    <div className="form-field">
      {title && <span>{title}</span>}
      <div className="form-group">
        <label
          htmlFor="MCCTARITAG.Microchip"
          onClick={handleHelp}
        >
          {label || 'Nº de microchip'}
          &nbsp;
          <Information />
        </label>

        <input
          className={`form-control${
            errors.MCCTARITAG?.Microchip ? ' hasError' : ''
          }`}
          type="text"
          id="MCCTARITAG.Microchip"
          placeholder={label || 'Nº de microchip'}
          {...register('MCCTARITAG.Microchip', {
            onChange: handleChange,
            required:
              required === true ? 'El nº de microchip es obligatorio.' : false,
            pattern: {
              value: /^[0-9]+$/i,
              message: 'Has introducido caracteres no permitidos.',
            },
            minLength: {
              value: 10,
              message: 'El nº de microchip es demasiado corto.',
            },
            maxLength: {
              value: 15,
              message: 'El nº de microchip es demasiado largo.',
            },
          })}
        />
        {errors.MCCTARITAG?.Microchip && (
          <div className="error">{errors.MCCTARITAG.Microchip.message}</div>
        )}
      </div>
      <div
        id="info-microchip"
        className="info-panel d-none"
      >
        <span onClick={handleHelp}>
          <Information />
        </span>
        El chip, o código alfanumérico de transpondedor, es un &nbsp;
        <strong>número único de identificación para tu perro</strong>. Se
        encuentra en <strong>su cartilla o pasaporte</strong>, y también puede
        verificarse en el veterinario, donde, mediante un lector especial, se
        pueden consultar los datos asociados al microchip.
      </div>
    </div>
  )
}
