import React from 'react'
import { WooCartProvider } from './contexts/WooCartContext'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'

import Onboarding from './components/Onboarding'
import Home from './components/Home'
import WooCheckout from './components/WooCheckout'

export default function App() {
  return (
    <WooCartProvider>
      <BrowserRouter basename="/">
        <Routes>
          <Route
            path="/flow/:step"
            element={<Onboarding />}
          />
          {/* <Route
            path="/finalizar-compra"
            element={<WooCheckout />}
          /> */}
          <Route
            path="*"
            element={
              <Home />
              // <Navigate
              //   to="/404"
              //   replace
              // />
            }
          />
        </Routes>
      </BrowserRouter>
    </WooCartProvider>
  )
}
