import React, { useEffect } from 'react'
import useAppNavigation from '../../hooks/useAppNavigation'
import './WooProducts.css'

export default function WooProducts() {
  const { currentStep } = useAppNavigation()

  // useEffect(() => {
  //   const productId = currentStep?.product?.id_product
  //   if (productId)
  //     document.getElementById(`faqs-${productId}`).classList.remove('d-none')
  //   console.log('useEffect', productId)
  // }, [currentStep])

  return (
    <>
      {currentStep.product && (
        <div id="woo-product">
          <div className="h3">{currentStep?.product?.title}</div>
          <div
            className="content"
            dangerouslySetInnerHTML={{
              __html: currentStep?.product?.content,
            }}
          ></div>
        </div>
      )}
    </>
  )
}
