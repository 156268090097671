import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useFormContext } from 'react-hook-form'
import useSessionStorage from './useSessionStorage'
import { useWooProduct } from './useWooProduct'

export default function useAppNavigation() {
  const navigate = useNavigate()
  const { step } = useParams()
  const { trigger, getValues } = useFormContext()
  const productSlug = location.pathname.split('/')[1]
  const { getProductContent } = useWooProduct()
  const [wooProducts, setWooProducts] = useState(null)

  const stepURL = steps
    .filter((route) => {
      return route.url === step
    })
    .shift()

  const wooProduct =
    getValues('dangerousBreed') === 'true'
      ? wooProducts?.filter((product) => product.id_product == 133).shift()
      : wooProducts?.filter((product) => product.id_product == 20).shift()

  const [currentStep, setCurrentStep] = useSessionStorage('currentStep', {
    ...stepURL,
    product: wooProduct,
  })

  const handlePrev = () => {
    const prevStep =
      currentStep.id > 0
        ? { ...steps[currentStep.id - 1], product: productSlug }
        : currentStep

    setCurrentStep(prevStep)
    navigate('/flow/' + prevStep.url)
    // console.log('handlePrev')
  }

  const handleNext = () => {
    let nextStep =
      currentStep.id < Object.keys(steps).length - 1
        ? { ...steps[currentStep.id + 1], product: productSlug }
        : currentStep

    trigger().then((response) => {
      nextStep = { ...nextStep, hasError: !response }
      if (nextStep.hasError === false) {
        setCurrentStep(nextStep)
        navigate('/flow/' + nextStep.url)
      }
    })
    // console.log('handleNext')
  }

  useEffect(() => {
    getProductContent().then((response) => {
      setWooProducts(response)
    })
  }, [])

  useEffect(() => {
    if (stepURL === undefined) {
      navigate('/flow/paso-1')
    }

    setCurrentStep(
      stepURL
        ? { ...stepURL, product: wooProduct }
        : { ...steps[0], product: wooProduct }
    )

    // console.log('useEffect', currentStep)
  }, [step, wooProduct])

  return { steps, currentStep, handlePrev, handleNext }
}

const steps = [
  {
    id: 0,
    url: 'paso-1',
  },
  {
    id: 1,
    url: 'paso-2',
  },
  {
    id: 2,
    url: 'paso-3',
  },
  {
    id: 3,
    url: 'paso-4',
  },
]
