import React, { useState, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { Information } from '../../assets/icons'
import useSessionStorage from '../../hooks/useSessionStorage'
import { initialFields } from '../../consts'

export default function DangerousBreed({ title, label, required = true }) {
  const {
    setValue,
    getValues,
    register,
    watch,
    formState: { errors },
  } = useFormContext()

  const fields = getValues()
  const [dangerous, setDangerous] = useState(null)
  const watchShowDangerous = watch('dangerousBreed', false)

  const [storageData, setStorageData] = useSessionStorage(
    'app_data',
    initialFields
  )

  const handleChange = (event) => {
    setDangerous(event.target.value)
    setStorageData(getValues())
  }

  useEffect(() => {
    if (dangerous == null) setDangerous(fields.dangerousBreed)
  }, [fields.dangerousBreed])

  return (
    <>
      <div className="form-field">
        <span htmlFor="dangerousBreed">
          {title || '¿Pertenece a una raza peligrosa?'}
        </span>

        <div
          id="dangerousBreed"
          className="form-group"
        >
          <div className="form-check form-check-inline">
            <input
              className="d-none"
              type="radio"
              id="notDangerousBreed"
              value={false}
              checked={dangerous === false}
              {...register('dangerousBreed', {
                onChange: handleChange,
                required:
                  required === true && dangerous == null
                    ? 'El campo es obligatorio.'
                    : false,
              })}
            />
            <label
              htmlFor="notDangerousBreed"
              className={`btn${
                dangerous === 'false' ? ' btn-primary' : ' btn-outline-primary'
              }`}
            >
              <span style={{ color: 'tomato' }}>(&#x2716;)</span> No
            </label>
          </div>

          <div className="form-check form-check-inline">
            <input
              className="d-none"
              type="radio"
              id="isDangerousBreed"
              value={true}
              checked={dangerous === true}
              {...register('dangerousBreed', {
                onChange: handleChange,
                required:
                  required === true && dangerous == null
                    ? 'El campo es obligatorio.'
                    : false,
              })}
            />
            <label
              htmlFor="isDangerousBreed"
              className={`btn${
                dangerous === 'true' ? ' btn-primary' : ' btn-outline-primary'
              }`}
            >
              Sí <span style={{ color: 'yellowgreen' }}>(&#x2714;)</span>
            </label>
          </div>

          {errors?.dangerousBreed && (
            <div className="error">{errors.dangerousBreed.message}</div>
          )}
        </div>

        {watchShowDangerous === 'true' && (
          <div className="info-panel">
            <Information />
            <div>Aclaración raza peligrosa</div>
          </div>
        )}
      </div>
    </>
  )
}
