import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useFormContext } from 'react-hook-form'
import useSessionStorage from './useSessionStorage'

export default function useAppNavigation() {
  const navigate = useNavigate()
  const { step } = useParams()
  const {
    trigger,
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext()

  const stepURL = steps
    .filter((route) => {
      return route.url === step
    })
    .shift()

  const [currentStep, setCurrentStep] = useSessionStorage('currentStep', {
    ...stepURL,
    id_product: 20,
  })

  function getIdProduct() {
    return getValues('dangerousBreed') == null ||
      getValues('dangerousBreed') === 'false'
      ? 20
      : 133
  }

  const handlePrev = () => {
    const prevStep =
      currentStep.id > 0
        ? {
            ...steps[currentStep.id - 1],
            id_product: getIdProduct(),
          }
        : currentStep

    setCurrentStep(prevStep)
    navigate('/flow/' + prevStep.url)
    // console.log('handlePrev')
  }

  const handleNext = () => {
    let nextStep =
      currentStep.id < Object.keys(steps).length - 1
        ? {
            ...steps[currentStep.id + 1],
            id_product: getIdProduct(),
          }
        : currentStep

    trigger().then((response) => {
      nextStep = { ...nextStep, hasError: !response }
      if (nextStep.hasError === false) {
        setCurrentStep(nextStep)
        navigate('/flow/' + nextStep.url)
      }
    })
    // console.log('handleNext')
  }

  const checkErrors = () => {
    const data = getValues()

    if (data.MCCRETARITAG.Nombre == null) {
      if (currentStep.id > 0) navigate('/flow/paso-1')
    }

    if (
      data.MCCRETARITAG.FecNacimiento == null ||
      data.MCCRETARITAG.Microchip == null ||
      data.MCCRETARITAG.Raza == null ||
      data.MCCRETARITAG.FecEfecto == null
    ) {
      if (currentStep.id > 1) navigate('/flow/paso-2')
    }

    if (
      data.MCCPERSTAG.TOMADOR.Apellido1 == null ||
      data.MCCPERSTAG.TOMADOR.Apellido2 == null ||
      data.MCCPERSTAG.TOMADOR.CodPostal == null ||
      data.MCCPERSTAG.TOMADOR.Email == null ||
      data.MCCPERSTAG.TOMADOR.FechaNacimiento == null ||
      data.MCCPERSTAG.TOMADOR.Nif == null ||
      data.MCCPERSTAG.TOMADOR.Nombre == null ||
      data.MCCPERSTAG.TOMADOR.TipoVia == null ||
      data.MCCPERSTAG.TOMADOR.NombreVia == null ||
      data.MCCPERSTAG.TOMADOR.NumVia == null
    ) {
      if (currentStep.id > 2) navigate('/flow/paso-3')
    }
  }

  useEffect(() => {
    if (stepURL === undefined) {
      navigate('/flow/paso-1')
    }

    setCurrentStep(
      stepURL
        ? {
            ...stepURL,
            id_product: getIdProduct(),
          }
        : {
            ...steps[0],
            id_product: getIdProduct(),
          }
    )

    checkErrors()
    // console.log('useEffect', currentStep)
  }, [step, getValues('dangerousBreed')])

  return { steps, currentStep, setCurrentStep, handlePrev, handleNext }
}

const steps = [
  {
    id: 0,
    url: 'paso-1',
  },
  {
    id: 1,
    url: 'paso-2',
  },
  {
    id: 2,
    url: 'paso-3',
  },
  {
    id: 3,
    url: 'paso-4',
  },
]
