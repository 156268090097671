import React from 'react'
import { useFormContext } from 'react-hook-form'
import useSessionStorage from '../../hooks/useSessionStorage'
import { initialFields, TipoViaParams } from '../../consts'

export default function TipoVia({ title, label, required = true }) {
  const {
    register,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext()

  const [storageData, setStorageData] = useSessionStorage(
    'app_data',
    initialFields
  )

  const handleChange = () => {
    const streetType = document.getElementById('MCCPERSTAG.TOMADOR.TipoVia')
    const streetTypeValue = streetType.options[streetType.selectedIndex].text
    setValue('WOO.streetType', streetTypeValue)

    setStorageData(getValues())
  }

  return (
    <div className="form-field">
      {title && <span>{title}</span>}
      <div className="form-group">
        <label htmlFor="MCCPERSTAG.TOMADOR.TipoVia">
          {label || 'Tipo de vía'}
        </label>
        <select
          id="MCCPERSTAG.TOMADOR.TipoVia"
          className={`form-control form-select${
            errors.MCCPERSTAG?.TOMADOR?.TipoVia ? ' hasError' : ''
          }`}
          defaultValue={''}
          {...register('MCCPERSTAG.TOMADOR.TipoVia', {
            onChange: handleChange,
            required:
              required === true ? 'El tipo de vía es obligatorio.' : false,
            pattern: {
              value: /[A-Z]{2}/,
              message: 'Has introducido caracteres no permitidos.',
            },
            maxLength: {
              value: 2,
              message: 'El tipo de vía es demasiado largo.',
            },
          })}
        >
          <option value="">Tipo</option>
          {TipoViaParams.map((tipo, index) => {
            return (
              <option
                key={index}
                value={tipo.cod}
              >
                {tipo.name}
              </option>
            )
          })}
        </select>
      </div>

      {errors?.MCCPERSTAG?.TOMADOR?.TipoVia && (
        <div className="error">
          <p>{errors.MCCPERSTAG.TOMADOR.TipoVia.message}</p>
        </div>
      )}
    </div>
  )
}
