import React from 'react'
import { useFormContext } from 'react-hook-form'
import useSessionStorage from '../../hooks/useSessionStorage'
import { initialFields } from '../../consts'

export default function NombreVia({ title, label, required = true }) {
  const {
    register,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext()

  const [storageData, setStorageData] = useSessionStorage(
    'app_data',
    initialFields
  )

  const handleChange = () => {
    setStorageData(getValues())
  }

  return (
    <div className="form-field row">
      {title && <span>{title}</span>}

      <div className="form-group">
        <label htmlFor="address">{label || 'Dirección'}</label>
        <input
          className={`form-control${
            errors?.MCCPERSTAG?.TOMADOR?.NombreVia ? ' hasError' : ''
          }`}
          type="text"
          id="MCCPERSTAG.TOMADOR.NombreVia"
          placeholder={label || 'Dirección'}
          {...register('MCCPERSTAG.TOMADOR.NombreVia', {
            onChange: handleChange,
            required:
              required === true ? 'La dirección es obligatoria.' : false,
            pattern: {
              value: /^[a-zA-ZÀ-ÖØ-öø-ÿ 0-9º º/\\.,-]*$/i,
              message: 'Has introducido caracteres no permitidos.',
            },
            minLength: {
              value: 5,
              message: 'La dirección es demasiado corta.',
            },
            maxLength: {
              value: 150,
              message: 'La dirección es demasiado larga.',
            },
          })}
        />
      </div>

      {errors?.MCCPERSTAG?.TOMADOR?.NombreVia && (
        <div className="error">
          <p>{errors.MCCPERSTAG.TOMADOR.NombreVia.message}</p>
        </div>
      )}
    </div>
  )
}
