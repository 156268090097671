import React from 'react'
import { useStoreCart } from '../../contexts/WooCartContext'
import './Stepper.css'

export default function Stepper({ content }) {
  const cart = useStoreCart()

  return (
    <section className="navStepper">
      {content &&
        Object.entries(content).map(([index, step]) => {
          const stepIndex = parseInt(index.replace('step_', ''))
          return (
            <div
              key={index}
              className={`step ${
                cart.currentStep.id === stepIndex - 1 ? 'current' : ''
              }${cart.currentStep.id > stepIndex - 1 ? 'active' : ''}`}
            >
              <div className="number">
                <span>
                  {cart.currentStep.id > stepIndex - 1 ? '' : stepIndex}
                </span>
              </div>
              <div className="content">
                <h3 className="h3">{step.title}</h3>
                {stepIndex - 1 === cart.currentStep.id && (
                  <>
                    <h4 className="h4">{step.subtitle}</h4>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: step.content,
                      }}
                    ></div>
                  </>
                )}
              </div>
            </div>
          )
        })}
    </section>
  )
}
