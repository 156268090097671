import React, { useState, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { initialFields, months } from '../../consts'
import useSessionStorage from '../../hooks/useSessionStorage'

export default function FecNacimiento({ label, title, required = true }) {
  const {
    clearErrors,
    setValue,
    register,
    getValues,
    formState: { errors },
  } = useFormContext()

  const dateNow = new Date()
  const now = dateNow.getFullYear()
  const maxYears = dateNow.getFullYear() - 20
  const years = []
  for (let i = now; i > maxYears; i--) {
    years.push(i)
  }

  const fieldDate = getValues('MCCRETARITAG.FecNacimiento')
  const [month, setMonth] = useState(parseInt(fieldDate?.substr(4, 2)))
  const [year, setYear] = useState(parseInt(fieldDate?.substr(0, 4)))

  const [storageData, setStorageData] = useSessionStorage(
    'app_data',
    initialFields
  )

  const checkValidDate = (date) => {
    const today = new Date()
    const inputDate = [
      parseInt(date.substring(0, 4)),
      parseInt(date.substring(4, 6)),
      parseInt(date.substring(6, 8)),
    ]
    const formDate = new Date(inputDate[0], inputDate[1], inputDate[2])

    if (
      formDate.getFullYear() === today.getFullYear() &&
      formDate.getMonth() > today.getMonth() + 1
    )
      return 'La fecha introducida no es válida.'
  }

  useEffect(() => {
    if (year && month) {
      clearErrors('MCCRETARITAG.FecNacimiento')
      setValue(
        'MCCRETARITAG.FecNacimiento',
        `${year}${month < 10 ? `0${month}` : month}01`
      )
      setStorageData(getValues())
    }
    // console.log('useEffect', year, month)
  }, [month, year])

  return (
    <div className="form-field">
      {title && <span>{title}</span>}

      <input
        type="hidden"
        id="MCCRETARITAG.FecNacimiento"
        placeholder={label || 'YYYYMMDD'}
        {...register('MCCRETARITAG.FecNacimiento', {
          required:
            required === true
              ? 'La fecha de nacimiento es obligatoria.'
              : false,
          pattern: {
            value: /^[0-9]{8}$/i,
            message: 'Has introducido caracteres no permitidos.',
          },
          validate: checkValidDate,
        })}
      />

      <div className="row">
        <div className="form-group col col-xs-6">
          <label htmlFor="months">Mes</label>
          <select
            id="months"
            className={`form-select form-control${
              errors.MCCRETARITAG?.FecNacimiento && !month ? ' hasError' : ''
            }`}
            value={month}
            onChange={(event) => {
              setMonth(event.target.value)
              // console.log(event.target.value);
            }}
          >
            <option hidden>Mes</option>
            {months.map((value, index) => {
              return (
                <option
                  key={index}
                  value={index + 1}
                >
                  {value}
                </option>
              )
            })}
          </select>
        </div>

        <div className="form-group col col-xs-6">
          <label htmlFor="years">Año</label>
          <select
            id="years"
            className={`form-select form-control${
              errors.MCCRETARITAG?.FecNacimiento && !year ? ' hasError' : ''
            }`}
            value={year}
            onChange={(event) => {
              setYear(event.target.value)
              // console.log(event.target.value);
            }}
          >
            <option hidden>Año</option>
            {years.map((value, index) => {
              return (
                <option
                  key={index}
                  value={value}
                >
                  {value}
                </option>
              )
            })}
          </select>
        </div>
      </div>

      {errors.MCCRETARITAG?.FecNacimiento && (
        <div className="error">{errors.MCCRETARITAG.FecNacimiento.message}</div>
      )}
    </div>
  )
}
