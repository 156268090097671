import React, { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useStoreCart } from '../contexts/WooCartContext'
import { useXML } from '../hooks/useXML'
import { provincias } from '../consts'

export default function CartResume() {
  const { getValues } = useFormContext()
  const cart = useStoreCart()

  const { jsonToXML } = useXML()
  const fieldsXML = jsonToXML(getValues())

  const data = getValues()

  const [petList, setPetList] = useState(null)

  const obj = {
    MCCTARITAG: Object.keys(data.MCCTARITAG)
      .filter((k) => data.MCCTARITAG[k] != null)
      .reduce((a, k) => ({ ...a, [k]: data.MCCTARITAG[k] }), {}),
    MCCPERSTAG: Object.keys(data.MCCPERSTAG)
      .filter((k) => data.MCCPERSTAG[k] != null)
      .reduce((a, k) => ({ ...a, [k]: data.MCCPERSTAG[k] }), {}),
    WOO: Object.keys(data.WOO)
      .filter((k) => data.WOO[k] != null)
      .reduce((a, k) => ({ ...a, [k]: data.WOO[k] }), {}),
  }

  function calculateAge(date) {
    if (!date) return

    const inputDate = [
      date.substring(0, 4),
      date.substring(4, 6),
      date.substring(6, 8),
    ]

    var today = new Date()
    var year = today.getFullYear()
    var month = today.getMonth() + 1
    var day = today.getDate()
    var yy = parseInt(inputDate[0])
    var mm = parseInt(inputDate[1])
    var dd = parseInt(inputDate[2])

    var years, months
    // months
    months = month - mm
    if (day < dd) {
      months = months - 1
    }
    // years
    years = year - yy
    if (month * 100 + day < mm * 100 + dd) {
      years = years - 1
      months = months + 12
    }

    return `${years}${years > 1 ? ' años' : ' año'} y ${months}${
      months > 1 ? ' meses' : ' mes'
    }`
  }

  useEffect(() => {
    if (cart.woocart) {
      let mascotas = []
      Object.entries(cart.woocart).map(([key, value]) => {
        mascotas.push(
          JSON.parse(
            value.app_data.MCCTARITAG.replace(new RegExp('\\\\', 'g'), '')
          )
        )
      })
      setPetList(mascotas)
    }
  }, [])

  return (
    <div id="resume">
      {petList && (
        <>
          {petList.map((pet, index) => {
            return (
              <div
                className="registered-pet"
                key={index}
              >
                <span className="h4">Animal {index + 1}</span>
                <div>
                  <strong>Nombre: </strong>
                  {pet.Nombre}
                </div>
                <div>
                  <strong>Edad: </strong>
                  {calculateAge(pet.FecNacimiento)}
                </div>
                <div>
                  <strong>Raza: </strong>
                  {pet.Raza}
                </div>
                <div>
                  <strong>Nº de Microchip: </strong>
                  {pet.Microchip}
                </div>
              </div>
            )
          })}
        </>
      )}

      <div className="h4">
        Datos de {obj.MCCTARITAG.Nombre ? obj.MCCTARITAG.Nombre : 'la mascota'}
      </div>
      <div>
        <strong>Nombre: </strong>
        {obj.MCCTARITAG.Nombre}
      </div>
      <div>
        <strong>Edad: </strong>
        {calculateAge(obj.MCCTARITAG.FecNacimiento)}
      </div>
      <div>
        <strong>Raza: </strong>
        {obj.MCCTARITAG.Raza}
      </div>
      <div>
        <strong>Nº de Microchip: </strong>
        {obj.MCCTARITAG.Microchip}
      </div>

      <div className="h4">Datos del tomador</div>
      <div>
        <strong>Nombre: </strong>
        {obj.MCCPERSTAG.TOMADOR.Nombre}
      </div>
      <div>
        <strong>Apellido: </strong>
        {obj.MCCPERSTAG.TOMADOR.Apellido1}
      </div>
      <div>
        <strong>Apellido: </strong>
        {obj.MCCPERSTAG.TOMADOR.Apellido2}
      </div>
      <div>
        <strong>NIF: </strong>
        {obj.MCCPERSTAG.TOMADOR.Nif}
      </div>
      <div>
        <strong>Teléfono: </strong>
        {obj.MCCPERSTAG.TOMADOR.TelefonoParticular}
      </div>

      <div>
        <strong>Provinicia: </strong>
        {provincias[obj.WOO.billing_state]}
      </div>
      <div>
        <strong>Ciudad: </strong>
        {obj.WOO.billing_city}
      </div>
      <div>
        <strong>Dirección: </strong>
        {obj.WOO.billing_address_1}
      </div>
      <div>
        <strong>Código postal: </strong>
        {obj.WOO.billing_postcode}
      </div>

      {/* <pre style={{ color: 'black' }}>
        Fields: {JSON.stringify(getValues(), null, 2)}
      </pre> */}
      <pre style={{ color: 'black' }}>XML: {fieldsXML}</pre>
      {/* <pre style={{ color: 'black' }}>
        Cart: {JSON.stringify(cart, null, 2)}
      </pre> */}
    </div>
  )
}
