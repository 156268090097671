/* eslint-disable react/prop-types */
import React from 'react'
import { useFormContext } from 'react-hook-form'
import useSessionStorage from '../../hooks/useSessionStorage'
import { initialFields } from '../../consts'

export default function TelefonoParticular({ label, title, required = true }) {
  const {
    register,
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext()

  const [storageData, setStorageData] = useSessionStorage(
    'app_data',
    initialFields
  )

  const handleChange = (event) => {
    if (event.target.value.length > 9) {
      setValue(
        'MCCPERSTAG.TOMADOR.TelefonoParticular',
        event.target.value.substring(0, 9)
      )
    }
    if (event.target.value.length === 9) setStorageData(getValues())
  }

  return (
    <div className="form-field">
      {title && <span>{title}</span>}

      <div className="form-group">
        <label htmlFor="MCCPERSTAG.TOMADOR.TelefonoParticular">
          {label || 'Teléfono'}
        </label>
        <input
          type="tel"
          id="MCCPERSTAG.TOMADOR.TelefonoParticular"
          className={`form-control${
            errors.MCCPERSTAG?.TOMADOR?.TelefonoParticular ? ' hasError' : ''
          }`}
          placeholder={label || 'Teléfono'}
          {...register('MCCPERSTAG.TOMADOR.TelefonoParticular', {
            onChange: handleChange,
            required: required === true ? 'El teléfono es obligatorio.' : false,
            pattern: {
              value: /^[0-9]+$/i,
              message: 'Has introducido caracteres no permitidos.',
            },
            minLength: {
              value: 9,
              message: 'El nº de teléfono tiene que contener 9 dígitos.',
            },
            maxLength: {
              value: 9,
              message: 'El nº de teléfono tiene que contener 9 dígitos.',
            },
          })}
        />
        {errors.MCCPERSTAG?.TOMADOR?.TelefonoParticular && (
          <div className="error">
            {errors.MCCPERSTAG.TOMADOR.TelefonoParticular.message}
          </div>
        )}
      </div>
    </div>
  )
}
