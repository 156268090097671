import React, { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { useWooCart } from '../../hooks/useWooCart'
import useAppNavigation from '../../hooks/useAppNavigation'
import useSessionStorage from '../../hooks/useSessionStorage'
import NavButtons from './NavButtons'
import { initialFields, provincias } from '../../consts'
import './CartResume.css'

export default function CartResume({ wooProducts }) {
  const [cart, setCart] = useState(null)
  const [totalPrice, setTotalPrice] = useState(0)
  const { getCart } = useWooCart()
  const { steps, currentStep, setCurrentStep } = useAppNavigation()
  const { getValues, setValue } = useFormContext()
  const [storageData, setStorageData] = useSessionStorage(
    'app_data',
    initialFields
  )
  const obj = getValues()

  const getTarificarPYS = async (objData) => {
    console.log('PYS -> Tarificar. Esperando respuesta...')

    const paymentButton = document.getElementById('payment-button')
    paymentButton.classList.add('disabled')
    paymentButton.innerHTML =
      '<span class="spinner-border" role="status" aria-hidden="true"></span>Confirmando compra...'

    // Call API PYS -> Tarificar
    const data = {
      action: 'get_tarificar_pys',
      MCCRETARITAG: JSON.stringify(objData.MCCRETARITAG),
      MCCPERSTAG: JSON.stringify(objData.MCCPERSTAG),
    }

    const response = await fetch(
      `${window.location.protocol}//${window.location.host}/wp-admin/admin-ajax.php`,
      {
        method: 'post',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: new URLSearchParams(data).toString(),
      }
    ).then((response) => response.json())

    if (response) {
      if (response?.STari?.SModa) {
        if (parseFloat(response?.STari?.SModa?.PrimaTotal) > 90) {
          setCurrentStep({ ...currentStep, id_product: 133 })
          setValue('dangerousBreed', 'true')
          setValue('MCCRETARITAG.NumPresupuesto', response.NumPresupuesto)
          // setValue('MCCRETARITAG.NumPropuesta', response.NumPresupuesto)
          setStorageData({
            ...getValues(),
          })
        } else {
          setCurrentStep({ ...currentStep, id_product: 20 })
          setValue('dangerousBreed', 'false')
          setValue('MCCRETARITAG.NumPresupuesto', response.NumPresupuesto)
          // setValue('MCCRETARITAG.NumPropuesta', response.NumPresupuesto)
          setStorageData({
            ...getValues(),
          })
        }

        paymentButton.classList.remove('disabled')
        paymentButton.innerHTML = 'PAGAR'
      }
      console.log('getTarificarPYS', response)
      console.log(getValues())
      return response
    }
  }

  useEffect(() => {
    if (cart?.woocart && currentStep.id === 3) {
      getTarificarPYS({ ...obj })
      console.log('useEffect')
    }
  }, [cart?.woocart, currentStep.id])

  useEffect(() => {
    getCart().then((response) => setCart(response))
    // console.log('useEffect', currentStep, totalPrice)
  }, [])

  useEffect(() => {
    if (wooProducts && currentStep) {
      var currentProduct = wooProducts
        .filter((product) => product.id_product == currentStep.id_product)
        .shift()
    }
    if (currentProduct) {
      setTotalPrice(currentProduct.price)
    }
    // console.log('useEffect', currentStep)
  }, [currentStep, wooProducts])

  useEffect(() => {
    if (wooProducts && currentStep) {
      var currentProduct = wooProducts
        .filter((product) => product.id_product == currentStep.id_product)
        .shift()

      if (currentProduct && window.dataLayer) {
        // dataLayer.push(function () {
        //   this.reset()
        // })

        if (currentStep.id == 0) {
          window.dataLayer.push({
            event: 'info_inicial_formulario',
            paginaUrl: `/flow/${currentStep.url}`,
            producto: currentProduct.title,
            precio: currentProduct.price,
          })
        }
        if (currentStep.id == 1) {
          window.dataLayer.push({
            event: 'datos_mascota_formulario',
            paginaUrl: `/flow/${currentStep.url}`,
            producto: currentProduct.title,
            precio: currentProduct.price,
            fechaNacimiento: getValues('MCCRETARITAG.FecNacimiento'),
            raza: getValues('MCCRETARITAG.Raza'),
          })
        }
        if (currentStep.id == 2) {
          window.dataLayer.push({
            event: 'datos_personales_formulario',
            paginaUrl: `/flow/${currentStep.url}`,
          })
        }
        if (currentStep.id == 3) {
          window.dataLayer.push({
            event: 'confirmacion_propuesta_formulario',
            paginaUrl: `/flow/${currentStep.url}`,
            precio: currentProduct.price,
          })
        }
        // console.log(window.dataLayer)
      }
    }
  }, [
    wooProducts,
    currentStep.id,
    currentStep.id_product,
    getValues('MCCRETARITAG.FecNacimiento'),
    getValues('MCCRETARITAG.Raza'),
  ])

  return (
    <>
      {cart?.woocart && (
        <div id="cart_resume">
          <div className="totals">
            <h4 className="h4">Precio total</h4>
            <div>
              <strong>
                {new Intl.NumberFormat('es-ES', {
                  style: 'currency',
                  currency: 'EUR',
                }).format(totalPrice)}
              </strong>
              &nbsp; al año
            </div>
          </div>

          {currentStep.id === Object.keys(steps).length - 1 && <NavButtons />}

          {currentStep.id > 1 && (
            <div className="current-person">
              <h4 className="h4">
                Tus datos
                {currentStep.id > Object.keys(steps).length - 2 && (
                  <Link to={`/flow/${steps[2].url}`}></Link>
                )}
              </h4>
              <div>
                <strong>Nombre: </strong>
                {obj.MCCPERSTAG.TOMADOR.Nombre}
              </div>
              <div>
                <strong>Apellidos: </strong>
                {obj.MCCPERSTAG.TOMADOR.Apellido1}
                &nbsp;
                {obj.MCCPERSTAG.TOMADOR.Apellido2}
              </div>
              <div>
                <strong>NIF: </strong>
                {obj.MCCPERSTAG.TOMADOR.Nif}
              </div>
              <div>
                <strong>Email: </strong>
                {obj.MCCPERSTAG.TOMADOR.Email}
              </div>
              <div>
                <strong>Teléfono: </strong>
                {obj.MCCPERSTAG.TOMADOR.TelefonoParticular}
              </div>

              <div>
                <strong>Provincia: </strong>
                {provincias[obj.WOO.billing_state]}
              </div>
              <div>
                <strong>Localidad: </strong>
                {obj.MCCPERSTAG.TOMADOR.Localidad}
              </div>
              <div>
                <strong>Dirección: </strong>
                {obj.WOO.streetType}&nbsp;
                {obj.MCCPERSTAG.TOMADOR.NombreVia}
                {obj.MCCPERSTAG.TOMADOR.NumVia &&
                obj.MCCPERSTAG.TOMADOR.NombreVia
                  ? `, ${obj.MCCPERSTAG.TOMADOR.NumVia}`
                  : ''}
                {obj.MCCPERSTAG.TOMADOR.Bloque &&
                obj.MCCPERSTAG.TOMADOR.NombreVia
                  ? `, bloque ${obj.MCCPERSTAG.TOMADOR.Bloque}`
                  : ''}
                {obj.MCCPERSTAG.TOMADOR.Portal &&
                obj.MCCPERSTAG.TOMADOR.NombreVia
                  ? `, portal ${obj.MCCPERSTAG.TOMADOR.Portal}`
                  : ''}
                {obj.MCCPERSTAG.TOMADOR.Escalera &&
                obj.MCCPERSTAG.TOMADOR.NombreVia
                  ? `, escalera ${obj.MCCPERSTAG.TOMADOR.Escalera}`
                  : ''}
                {obj.MCCPERSTAG.TOMADOR.Piso && obj.MCCPERSTAG.TOMADOR.NombreVia
                  ? `, piso ${obj.MCCPERSTAG.TOMADOR.Piso}`
                  : ''}
                {obj.MCCPERSTAG.TOMADOR.Puerta &&
                obj.MCCPERSTAG.TOMADOR.NombreVia
                  ? `, puerta ${obj.MCCPERSTAG.TOMADOR.Puerta}`
                  : ''}
              </div>
              <div>
                <strong>Código postal: </strong>
                {obj.MCCPERSTAG.TOMADOR.CodPostal}
              </div>
            </div>
          )}

          {currentStep.id > 0 && (
            <div className="current-pet">
              <h4 className="h4">
                Datos de tu mascota
                {currentStep.id > 1 && (
                  <Link to={`/flow/${steps[0].url}`}></Link>
                )}
              </h4>
              <div>
                <strong>Nombre: </strong>
                {obj.MCCRETARITAG.Nombre}
              </div>
              <div>
                <strong>Edad: </strong>
                {calculateAge(obj.MCCRETARITAG.FecNacimiento)}
              </div>
              <div>
                <strong>Raza: </strong>
                {obj.MCCRETARITAG.Raza}
              </div>
              <div>
                <strong>Nº de Microchip: </strong>
                {obj.MCCRETARITAG.Microchip}
              </div>
            </div>
          )}

          {/* {Object.keys(cart?.woocart).length > 0 && (
            <div>
              {Object.entries(cart?.woocart).map(([index, product]) => {
                // console.log(product)
                if (
                  product?.MCCRETARITAG &&
                  product.MCCRETARITAG.Microchip !==
                    obj.MCCRETARITAG.Microchip &&
                  product.MCCRETARITAG.Nombre !== obj.MCCRETARITAG.Nombre
                ) {
                  return (
                    <div className="product-line">
                      <div className="h5">Otras mascotas añadidas:</div>
                      <li key={index}>
                        {pet?.Nombre}
                        <strong>
                          {new Intl.NumberFormat('es-ES', {
                            style: 'currency',
                            currency: 'EUR',
                          }).format(product?.line_total)}
                        </strong>
                      </li>
                    </div>
                  )
                }
              })}
            </div>
          )} */}
        </div>
      )}
    </>
  )
}

function calculateAge(date) {
  if (!date) return

  const inputDate = [
    date.substring(0, 4),
    date.substring(4, 6),
    date.substring(6, 8),
  ]

  var today = new Date()
  var year = today.getFullYear()
  var month = today.getMonth() + 1
  var day = today.getDate()
  var yy = parseInt(inputDate[0])
  var mm = parseInt(inputDate[1])
  var dd = parseInt(inputDate[2])

  var years, months
  // months
  months = month - mm
  if (day < dd) {
    months = months - 1
  }
  // years
  years = year - yy
  if (month * 100 + day < mm * 100 + dd) {
    years = years - 1
    months = months + 12
  }

  var petAge = ''
  if (years > 0) petAge += years > 1 ? ` ${years} años ` : ` ${years} año `
  if (months > 0) petAge += 'y '
  if (months > 0) petAge += months > 1 ? `${months} meses` : `${months} mes`
  else if (months === 0) petAge += '1 mes'
  return petAge
}
