import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { initialFields } from '../consts'

import Nombre from './MCCTARITAG/Nombre'
import DangerousBreed from './MCCTARITAG/DangerousBreed'
import { useWooProduct } from '../hooks/useWooProduct'
import useSessionStorage from '../hooks/useSessionStorage'

export default function Home() {
  const [wooProducts, setWooProducts] = useState(null)
  const [storageData] = useSessionStorage('app_data')
  const methods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: storageData ? storageData : initialFields,
  })
  const { getProductContent } = useWooProduct()

  useEffect(() => {
    getProductContent().then((response) => setWooProducts(response))
  }, [])

  const onSubmit = (data) => {
    // Add to sessionStorage
    window.sessionStorage.setItem(
      'app_data',
      JSON.stringify(methods.getValues(), null, 2)
    )
    window.location.href = `${location.origin}/flow/paso-2`
    console.log('onSubmit', data)
  }

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <h2 className="h2">
            Contrata <span>Tu Seguro</span> ahora
          </h2>
          <Nombre />
          <DangerousBreed wooProducts={wooProducts} />
          <div id="navigation_buttons">
            <button
              type="submit"
              className="btn btn-primary"
            >
              Contrata tu seguro
            </button>
          </div>
        </form>
      </FormProvider>
    </>
  )
}
