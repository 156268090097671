import React, { useState, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { Information } from '../../assets/icons'
import useSessionStorage from '../../hooks/useSessionStorage'
import { initialFields } from '../../consts'

export default function DangerousBreed({
  wooProducts,
  title,
  label,
  required = true,
}) {
  const {
    setValue,
    getValues,
    register,
    watch,
    formState: { errors },
  } = useFormContext()

  const fields = getValues()
  const [dangerous, setDangerous] = useState(null)
  // const watchShowDangerous = watch('dangerousBreed', false)
  const [storageData, setStorageData] = useSessionStorage(
    'app_data',
    initialFields
  )

  const handleChange = (event) => {
    setDangerous(event.target.value)
    setStorageData(getValues())
  }

  const handleHelp = () => {
    const elem = document.getElementById('info-dangerous')
    if (elem?.classList.contains('d-none')) elem?.classList.remove('d-none')
    else elem?.classList.add('d-none')
  }

  useEffect(() => {
    if (dangerous == null) setDangerous(fields.dangerousBreed)
    if (storageData.dangerousBreed != null)
      setDangerous(storageData.dangerousBreed)
  }, [fields.dangerousBreed])

  return (
    <>
      <div className="form-field">
        <span
          className="title"
          htmlFor="dangerousBreed"
          onClick={handleHelp}
        >
          {title || (
            <>
              ¿Pertenece a una raza peligrosa? <Information />
            </>
          )}
        </span>

        <div
          id="dangerousBreed"
          className="form-group"
        >
          <div className="form-check form-check-inline">
            <input
              className="d-none"
              type="radio"
              id="notDangerousBreed"
              value={false}
              checked={dangerous === false}
              {...register('dangerousBreed', {
                onChange: handleChange,
                required:
                  required === true && dangerous == null
                    ? 'El campo es obligatorio.'
                    : false,
              })}
            />
            <label
              htmlFor="notDangerousBreed"
              className={`btn${
                dangerous === 'false' ? ' btn-primary' : ' btn-outline-primary'
              }`}
            >
              <div class="dog-icon"></div>
              <div>Perro No Peligroso</div>
              {wooProducts && (
                <div className="price">
                  <strong>
                    {wooProducts
                      ? new Intl.NumberFormat('es-ES', {
                          style: 'currency',
                          currency: 'EUR',
                        }).format(wooProducts[0].price)
                      : ''}
                  </strong>
                  &nbsp; al año
                </div>
              )}
            </label>
          </div>

          <div className="form-check form-check-inline">
            <input
              className="d-none"
              type="radio"
              id="isDangerousBreed"
              value={true}
              checked={dangerous === true}
              {...register('dangerousBreed', {
                onChange: handleChange,
                required:
                  required === true && dangerous == null
                    ? 'El campo es obligatorio.'
                    : false,
              })}
            />
            <label
              htmlFor="isDangerousBreed"
              className={`btn${
                dangerous === 'true' ? ' btn-primary' : ' btn-outline-primary'
              }`}
            >
              <div class="dog-icon ppp"></div>
              <div>Perro Potencialmente Peligroso</div>
              {wooProducts && (
                <div className="price">
                  <strong>
                    {wooProducts
                      ? new Intl.NumberFormat('es-ES', {
                          style: 'currency',
                          currency: 'EUR',
                        }).format(wooProducts[1].price)
                      : ''}
                  </strong>
                  &nbsp; al año
                </div>
              )}
            </label>
          </div>

          {errors?.dangerousBreed && (
            <div className="error">{errors.dangerousBreed.message}</div>
          )}
        </div>

        <div
          id="info-dangerous"
          className="info-panel d-none"
        >
          <p>
            <span onClick={handleHelp}>
              <Information />
            </span>
            Las razas consideradas&nbsp;
            <strong>perros potencialmente peligrosos (PPP)</strong> a nivel
            estatal incluyen:
          </p>
          <p>
            <strong>
              Tosa Inu, Akita Inu, Rottweiler, Fila Brasileiro, Dogo Argentino,
              American Pitbull Terrier, Staffordshire Pitbull Terrier y American
              Staffordshire Terrier.
            </strong>
          </p>
          <p>
            Algunas&nbsp;
            <strong>Comunidades Autónomas pueden ampliar este listado</strong>,
            por lo que es importante&nbsp;
            <strong>verificarlo antes de asegurar a tu perro</strong>. Además,
            si viajas a una zona donde tu mascota sea clasificada como PPP,
            deberás asegurarlo bajo esa categoría.
          </p>
        </div>
      </div>
    </>
  )
}
