import React from 'react'
import { useFormContext } from 'react-hook-form'
import useSessionStorage from '../../hooks/useSessionStorage'
import { initialFields } from '../../consts'

export default function Puerta({ title, label, required = false }) {
  const {
    register,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext()

  const [storageData, setStorageData] = useSessionStorage(
    'app_data',
    initialFields
  )

  const handleChange = () => {
    setStorageData(getValues())
  }

  return (
    <div className="form-field">
      {title && <span>{title}</span>}
      <div className="form-group">
        <label htmlFor="MCCPERSTAG.TOMADOR.Puerta">{label || 'Puerta'}</label>
        <input
          id="MCCPERSTAG.TOMADOR.NumVia"
          className={`form-control${
            errors?.MCCPERSTAG?.TOMADOR?.Puerta ? ' hasError' : ''
          }`}
          type="text"
          {...register('MCCPERSTAG.TOMADOR.Puerta', {
            onChange: handleChange,
            required: required === true ? 'El Puerta es obligatoria.' : false,
            pattern: {
              value: /^[A-Za-z0-9-]*$/i,
              message: 'Has introducido caracteres no permitidos.',
            },
            maxLength: {
              value: 10,
              message: 'La puerta es demasiado larga.',
            },
          })}
        />
      </div>

      {errors?.MCCPERSTAG?.TOMADOR?.Puerta && (
        <div className="error">
          <p>{errors.MCCPERSTAG.TOMADOR.Puerta.message}</p>
        </div>
      )}
    </div>
  )
}
