import React, { useEffect, useState } from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import Select from 'react-select'
import useSessionStorage from '../../hooks/useSessionStorage'
import useCSV from '../../hooks/useCSV'
import { initialFields } from '../../consts'

export default function Raza({ label, title, required = true }) {
  const {
    control,
    register,
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext()

  const [breeds, setBreeds] = useState(null)

  const [storageData, setStorageData] = useSessionStorage(
    'app_data',
    initialFields
  )
  const { readFile } = useCSV()

  const handleChange = ({ value, label }) => {
    setValue('MCCTARITAG.CodRaza', value)
    setValue('MCCTARITAG.Raza', label)
    setStorageData(getValues())
  }

  useEffect(() => {
    readFile(
      `${window.location.protocol}//${window.location.host}/wp-content/themes/petk9/src/assets/RazasMascotas.csv`
    ).then((response) => {
      const options = Object.values(response).map((breed) => ({
        value: breed.cod_raza,
        label: breed.des_raza,
      }))
      setBreeds(options)
    })
  }, [])

  return (
    <div className="form-field">
      {title && <span>{title}</span>}
      <div className="form-group">
        <label htmlFor="MCCTARITAG.Raza">{label || 'Raza'}</label>

        <input
          className="form-control"
          type="hidden"
          {...register('MCCTARITAG.Raza', {
            required: required === true ? 'La raza es obligatoria.' : false,
            pattern: {
              value: /^[a-zA-ZÀ-ÖØ-öø-ÿ ]+$/i,
              message: 'Has introducido caracteres no permitidos.',
            },
            maxLength: {
              value: 150,
              message: 'La raza es demasiado larga.',
            },
          })}
        />

        {breeds && (
          <Controller
            control={control}
            name="MCCTARITAG.CodRaza"
            rules={{
              required: {
                value: true,
                message: 'Tiene que seleccionar una raza.',
              },
            }}
            render={({ field, value, name, ref }) => (
              <Select
                id="CodRaza"
                name={field.name}
                className={`react-select-container${
                  errors.MCCTARITAG?.Microchip ? ' hasError' : ''
                }`}
                classNamePrefix="react-select"
                placeholder="Selecciona una raza..."
                inputRef={field.ref}
                options={breeds}
                value={breeds.find(
                  (c) => c.value === getValues('MCCTARITAG.CodRaza')
                )}
                onChange={(val) => {
                  handleChange(val)
                }}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 4,
                  colors: {
                    ...theme.colors,
                    //   //after select dropdown option
                    //   primary50: 'blue',
                    //   //Border and Background dropdown color
                    primary: '#00b3ba',
                    //   //Background hover dropdown color
                    //   // primary25: "yellow",
                    //   //Background color
                    //   // neutral0: "black",
                    //   //Border before select
                    //   // neutral20: "#CAFFCA",
                    //   //Hover border
                    //   neutral30: '#ccc',
                    //   //No options color
                    //   // neutral40: "#CAFFCA",
                    //   //Select color
                    // neutral50: 'pink',
                    //   //arrow icon when click select
                    neutral60: '#00b3ba',
                    //   //Text color
                    //   neutral80: '#222',
                  },
                })}
              />
            )}
          />
        )}

        {errors.MCCTARITAG?.Raza && (
          <div className="error">{errors.MCCTARITAG.Raza.message}</div>
        )}
      </div>
    </div>
  )
}
