import React from 'react'
import { WooCartProvider } from './contexts/WooCartContext'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'

import Onboarding from './components/Onboarding'
import Home from './components/Home'
import WooCheckout from './components/WooCheckout'

export default function App() {
  return (
    <WooCartProvider>
      <BrowserRouter basename="/">
        {/* 👈 Renders at /onboarding/ */}
        <Routes>
          <Route
            path="/"
            element={<Home />}
          />
          <Route
            path="/finalizar-compra"
            element={<WooCheckout />}
          />
          <Route
            path="/onboarding"
            element={<Onboarding />}
          >
            <Route
              path=":step"
              element={<Onboarding />}
            />
          </Route>
          <Route
            path="*"
            element={
              <Navigate
                to="onboarding/"
                replace
              />
            }
          />
        </Routes>
      </BrowserRouter>
    </WooCartProvider>
  )
}
