import React from 'react'
import { useFormContext } from 'react-hook-form'
import useSessionStorage from '../../hooks/useSessionStorage'
import { initialFields } from '../../consts'

export default function Poblacion({ title, label, required = true }) {
  const {
    register,
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext()

  const [storageData, setStorageData] = useSessionStorage(
    'app_data',
    initialFields
  )

  const handleChange = () => {
    setValue('MCCPERSTAG.TOMADOR.Localidad', getValues('WOO.billing_city'))
    setStorageData(getValues())
  }

  return (
    <div className="form-field">
      {title && <span>{title}</span>}
      <div className="form-group">
        <label htmlFor="WOO.billing_city">{label || 'Población'}</label>
        <input
          className={`form-control${
            errors.WOO?.billing_city ? ' hasError' : ''
          }`}
          type="text"
          id="WOO.billing_city"
          placeholder={label || 'Población'}
          {...register('WOO.billing_city', {
            onChange: handleChange,
            required:
              required === true ? 'La población es obligatoria.' : false,
            pattern: {
              value: /^[a-zA-ZÀ-ÖØ-öø-ÿ -()"]+$/i,
              message: 'Has introducido caracteres no permitidos.',
            },
            minLength: {
              value: 3,
              message: 'La población es demasiado corta.',
            },
            maxLength: {
              value: 150,
              message: 'La población es demasiado larga.',
            },
          })}
        />

        {errors.WOO?.billing_city && (
          <div className="error">{errors.WOO?.billing_city.message}</div>
        )}
      </div>
    </div>
  )
}
