import React, { useEffect, useState } from 'react'
import { useStoreCart } from '../contexts/WooCartContext'
import { FormProvider, useForm } from 'react-hook-form'
import { useXML } from '../hooks/useXML'
import { initialFields } from '../consts'
import './onboarding.css'

import NavButtons from './navigation/NavButtons'
import CartResume from './navigation/CartResume'

import useSessionStorage from '../hooks/useSessionStorage'
import Stepper from './navigation/Stepper'
import FormStep from './navigation/FormStep'
import { useFormContent } from '../hooks/useFormContent'
import { useWooProduct } from '../hooks/useWooProduct'

export default function Onboarding() {
  const [content, setContent] = useState(null)
  const [wooProducts, setWooProducts] = useState(null)
  const [storageData] = useSessionStorage('app_data')
  const methods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: storageData ? storageData : initialFields,
  })

  const cart = useStoreCart()
  const { getContent } = useFormContent()
  const { getProductContent } = useWooProduct()

  const onSubmit = () => {
    console.log('onSubmit', methods.getValues())
  }

  useEffect(() => {
    getContent().then((response) => setContent(response['/flow']))
    getProductContent().then((response) => setWooProducts(response))
  }, [])

  useEffect(() => {
    const { unsubscribe } = methods.watch(() => {
      const { jsonToXML } = useXML()
      const fieldsXML = jsonToXML(methods.getValues())
      console.log(fieldsXML)
    })
    return () => unsubscribe()
  }, [methods.watch])

  return (
    <>
      <FormProvider {...methods}>
        <form
          id="onboarding"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <div className="container row inner">
            <section className="col col-12 col-md-3">
              <Stepper content={content} />
            </section>

            <section className="col col-12 col-md-6">
              <FormStep
                content={content}
                wooProducts={wooProducts}
              />
              {cart.currentStep?.id < 3 && <NavButtons />}
            </section>

            <section className="col col-12 col-md-3">
              <CartResume wooProducts={wooProducts} />
            </section>
          </div>
        </form>
      </FormProvider>
    </>
  )
}
