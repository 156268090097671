import React, { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import useSessionStorage from '../../hooks/useSessionStorage'
import { initialFields } from '../../consts'

export default function Nif({ label, title, required = true }) {
  const {
    register,
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext()

  const [storageData, setStorageData] = useSessionStorage(
    'app_data',
    initialFields
  )

  const handleChange = (event) => {
    if (event.target.value.length > 9) {
      setValue('MCCPERSTAG.TOMADOR.Nif', event.target.value.substring(0, 9))
    }
    if (event.target.value.length === 9) {
      setValue('MCCPERSTAG.TOMADOR.Nif', event.target.value)
      setStorageData(getValues())
    }
  }

  function validateNIF(value) {
    var validChars = 'TRWAGMYFPDXBNJZSQVHLCKET'
    var nifRexp = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKET]$/i
    var nieRexp = /^[XYZ][0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKET]$/i
    var str = value.toString().toUpperCase()

    if (!nifRexp.test(str) && !nieRexp.test(str)) return false

    var nie = str.replace(/^[X]/, '0').replace(/^[Y]/, '1').replace(/^[Z]/, '2')

    var letter = str.substr(-1)
    var charIndex = parseInt(nie.substr(0, 8)) % 23

    if (validChars.charAt(charIndex) === letter) return true
    return false
  }

  return (
    <div className="form-field">
      {title && <span>{title}</span>}
      <div className="form-group">
        <label htmlFor="MCCTARITAG.TOMADOR.Nif">{label || 'NIF'}</label>
        <input
          type="text"
          id="MCCTARITAG.TOMADOR.Nif"
          className={`form-control${
            errors.MCCPERSTAG?.TOMADOR?.Nif ? ' hasError' : ''
          }`}
          placeholder={label || 'NIF'}
          {...register('MCCPERSTAG.TOMADOR.Nif', {
            onChange: handleChange,
            required: required === true ? 'El NIF es obligatorio.' : false,
            validate: (value) => {
              if (validateNIF(value) === false) {
                return 'Debe introducir un NIF válido.'
              }
              return true
            },
          })}
        />
        {errors.MCCPERSTAG?.TOMADOR.Nif && (
          <div className="error">{errors.MCCPERSTAG.TOMADOR.Nif.message}</div>
        )}
      </div>
    </div>
  )
}
