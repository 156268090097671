/* eslint-disable react/prop-types */
import React from 'react'
import { useFormContext } from 'react-hook-form'
import useSessionStorage from '../../hooks/useSessionStorage'
import { initialFields } from '../../consts'

export default function Apellido1({ label, title, required = true }) {
  const {
    register,
    getValues,
    formState: { errors },
  } = useFormContext()

  const [storageData, setStorageData] = useSessionStorage(
    'app_data',
    initialFields
  )

  const handleChange = () => {
    setStorageData(getValues())
  }

  return (
    <div className="form-field">
      {title && <span>{title}</span>}
      <div className="form-group">
        <label htmlFor="MCCPERSTAG.TOMADOR.Apellido1">
          {label || 'Primer apellido'}
        </label>
        <input
          type="text"
          id="MCCPERSTAG.TOMADOR.Apellido1"
          className={`form-control${
            errors.MCCPERSTAG?.TOMADOR.Apellido1 ? ' hasError' : ''
          }`}
          placeholder={label || 'Primer apellido'}
          {...register('MCCPERSTAG.TOMADOR.Apellido1', {
            onChange: handleChange,
            required: required === true ? 'El apellido es obligatorio.' : false,
            pattern: {
              value: /^[a-zA-ZÀ-ÖØ-öø-ÿ ]+$/i,
              message: 'Has introducido caracteres no permitidos.',
            },
            maxLength: {
              value: 150,
              message: 'El apellido es demasiado largo.',
            },
          })}
        />
        {errors.MCCPERSTAG?.TOMADOR.Apellido1 && (
          <div className="error">
            {errors.MCCPERSTAG.TOMADOR.Apellido1.message}
          </div>
        )}
      </div>
    </div>
  )
}
