import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useFormContext } from 'react-hook-form'
import useSessionStorage from './useSessionStorage'

export default function useAppNavigation() {
  const navigate = useNavigate()
  const { step } = useParams()
  const { trigger } = useFormContext()

  const stepURL = steps
    .filter((route) => {
      return route.url.includes(step)
    })
    .shift()

  const [currentStep, setCurrentStep] = useSessionStorage(
    'currentStep',
    stepURL
  )

  const handlePrev = () => {
    const prevStep =
      currentStep.id > 0 ? steps[currentStep.id - 1] : currentStep

    setCurrentStep(prevStep)
    navigate(prevStep.url)
    // console.log('handlePrev')
  }

  const handleNext = () => {
    let nextStep =
      currentStep.id < Object.keys(steps).length - 1
        ? steps[currentStep.id + 1]
        : currentStep

    trigger().then((response) => {
      nextStep = { ...nextStep, hasError: !response }
      if (nextStep.hasError === false) {
        setCurrentStep(nextStep)
        navigate(nextStep.url)
      }
    })
    // console.log('handleNext')
  }

  useEffect(() => {
    if (stepURL === undefined) {
      navigate(steps[0].url)
    }
    setCurrentStep(stepURL ? stepURL : steps[0])
    // console.log('useEffect', currentStep)
  }, [])

  return { steps, currentStep, handlePrev, handleNext }
}

const steps = [
  {
    id: 0,
    url: '/onboarding/',
  },
  {
    id: 1,
    url: '/onboarding/mascota/',
  },
  {
    id: 2,
    url: '/onboarding/asegurado/',
  },
  {
    id: 3,
    url: '/onboarding/resumen/',
  },
]
