import React from 'react'
import useAppNavigation from '../../hooks/useAppNavigation'

import Nombre from '../MCCTARITAG/Nombre'
import DangerousBreed from '../MCCTARITAG/DangerousBreed'
import Microchip from '../MCCTARITAG/Microchip'
import FecNacimiento from '../MCCTARITAG/FecNacimiento'
import Raza from '../MCCTARITAG/Raza'
import NombrePersona from '../MCCPERSTAG/NombrePersona'
import Apellido1 from '../MCCPERSTAG/Apellido1'
import Apellido2 from '../MCCPERSTAG/Apellido2'
import Nif from '../MCCPERSTAG/Nif'
import TelefonoParticular from '../MCCPERSTAG/TelefonoParticular'
import Email from '../MCCPERSTAG/Email'
import AdultAge from '../MCCPERSTAG/AdultAge'
import CodigoPostal from '../WOO/CodigoPostal'
import Direccion from '../WOO/Direccion'
import Provincia from '../WOO/Provincia'
import Poblacion from '../WOO/Poblacion'
import Consentimiento from '../MCCPERSTAG/Consentimiento'
import WooProducts from './WooProducts'
import { Information } from '../../assets/icons'
import ConsentimientoContrato from '../MCCPERSTAG/ConsentimientoContrato'

export default function FormStep({ content, wooProducts }) {
  const { currentStep } = useAppNavigation()

  const handleHelp = () => {
    console.log('ok')
    const elem = document.getElementById('help-step-2')
    if (elem.classList.contains('d-none')) elem.classList.remove('d-none')
    else elem.classList.add('d-none')
  }

  return (
    <div>
      {currentStep && content && (
        <>
          <h1>
            {content['step_' + (currentStep.id + 1)]?.title}
            {currentStep?.id == 2 && (
              <span onClick={handleHelp}>
                &nbsp;
                <Information />
              </span>
            )}
          </h1>
        </>
      )}

      {currentStep?.id == 0 && (
        <>
          <Nombre />
          <DangerousBreed wooProducts={wooProducts} />
        </>
      )}
      {currentStep?.id == 1 && (
        <>
          <Microchip />
          <FecNacimiento title="Fecha de nacimiento" />
          <Raza />
        </>
      )}
      {currentStep?.id == 2 && (
        <>
          <div
            id="help-step-2"
            className="info-panel d-none"
          >
            <span onClick={handleHelp}>
              <Information />
            </span>
            El <strong>tomador y asegurado deben ser la misma persona</strong>.
            El seguro{' '}
            <strong>
              cubre tanto a los propietarios como a los poseedores del perro
            </strong>
            , incluyendo a quienes lo estén paseando en el momento de causar
            daños a terceros.
          </div>

          <NombrePersona />
          <div className="row">
            <div className="col col-6">
              <Apellido1 />
            </div>
            <div className="col col-6">
              <Apellido2 />
            </div>
            <div className="col col-6">
              <Nif />
            </div>
            <div className="col col-6">
              <TelefonoParticular />
            </div>
            <div className="col col-12 col-md-6">
              <Email />
            </div>
            <div className="col col-12 col-md-6">
              <AdultAge />
            </div>
          </div>
          <CodigoPostal />
          <Direccion />
          <Provincia />
          <Poblacion />
          {/* <Consentimiento /> */}
        </>
      )}
      {currentStep?.id == 3 && (
        <>
          <WooProducts />
          {/* <ConsentimientoContrato /> */}
        </>
      )}
    </div>
  )
}
