import React, { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { initialFields, PostalCodeProvincias } from '../../consts'
import useSessionStorage from '../../hooks/useSessionStorage'
import useCSV from '../../hooks/useCSV'

export default function CodigoPostal({ title, label, required = true }) {
  const {
    register,
    getValues,
    setValue,
    clearErrors,
    formState: { errors },
  } = useFormContext()

  const [municipios, setMunicipios] = useState()
  const [filtered, setFiletered] = useState([])

  const [storageData, setStorageData] = useSessionStorage(
    'app_data',
    initialFields
  )

  const handleChange = (event) => {
    if (event.target.value.length > 3) {
      setFiletered(
        municipios.filter(({ cod_postal }) =>
          String(cod_postal).startsWith(event.target.value)
        )
      )
    } else {
      setFiletered([])
    }

    setStorageData(getValues())
    console.log('handleChange', filtered)
  }

  const { readFile } = useCSV()
  useEffect(() => {
    readFile(
      `${window.location.protocol}//${window.location.host}/wp-content/themes/petk9/src/assets/Localidades.csv`
    ).then((response) => {
      const options = response.map((localidad, index) => {
        const keys = Object.keys(localidad)
        const data = Object.values(localidad)
        return {
          cod_postal: data[0],
          provincia: keys[1],
          poblacion: data[1],
        }
      })
      setMunicipios(options)
    })
    // console.log('useEffect', municipios)
  }, [])

  return (
    <div className="form-field">
      {title && <span>{title}</span>}
      <div className="form-group">
        <label htmlFor="WOO.billing_postcode">{label || 'Código postal'}</label>
        <input
          className={`form-control${
            errors.WOO?.billing_postcode ? ' hasError' : ''
          }`}
          type="text"
          id="WOO.billing_state"
          placeholder={label || 'Código postal'}
          {...register('WOO.billing_postcode', {
            onChange: handleChange,
            required: {
              value: true,
              message: 'El código postal es obligatorio.',
            },
            pattern: {
              value: /[0-9]{5}/,
              message: 'Error en el formato de código postal.',
            },
            maxLength: {
              value: 5,
              message: 'Error en el formato de código postal.',
            },
          })}
        />

        {errors.WOO?.billing_postcode && (
          <div className="error">{errors.WOO?.billing_postcode.message}</div>
        )}

        {filtered.length > 0 && (
          <div
            id="municipios-popup"
            className="d-flex py-2"
          >
            <div
              className="close"
              onClick={() => setFiletered([])}
            >
              🗙
            </div>
            {filtered.map((localidad) => (
              <div
                className="municipio"
                onClick={() => {
                  //Set form values and storage
                  setValue(
                    'WOO.billing_postcode',
                    `${localidad.cod_postal.padStart(5, '0')}`
                  )
                  setValue('WOO.billing_city', localidad.poblacion)
                  let prov = PostalCodeProvincias.filter((provincia) =>
                    localidad.cod_postal
                      .padStart(5, '0')
                      .startsWith(provincia.CPRO)
                  ).shift()
                  console.log(prov)
                  setValue('WOO.billing_state', prov.woo)
                  setStorageData(getValues())

                  //Clean form errors and reset filters
                  clearErrors('WOO.billing_postcode')
                  clearErrors('WOO.billing_city')
                  setFiletered([])
                }}
              >
                {localidad.poblacion}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}
