import React from 'react'
import { useFormContext } from 'react-hook-form'
import useSessionStorage from '../../hooks/useSessionStorage'
import { initialFields } from '../../consts'

export default function Consentimiento({ title, label, required = true }) {
  const {
    setValue,
    register,
    getValues,
    clearErrors,
    formState: { errors },
  } = useFormContext()
  const fields = getValues('CONSENTIMIENTOS')

  const [storageData, setStorageData] = useSessionStorage(
    'app_data',
    initialFields
  )

  const handleChange = (event) => {
    clearErrors('CONSENTIMIENTOS')
    const consentimiento = {
      CONSENTIMIENTO: {
        CLAVE: event.target.id,
        VERSION: '1.0',
        VALOR: event.target.checked === false ? 'N' : 'S',
      },
    }

    setValue('CONSENTIMIENTOS', { ...fields, ...consentimiento })
    setStorageData(getValues())
    // console.log('handleChange')
  }

  return (
    <div className="form-group">
      <div className="form-field">
        <input
          type="hidden"
          {...register('CONSENTIMIENTOS', {
            required:
              required === true ? 'Debe de aceptar las condiciones.' : false,
            validate: (value) => {
              if (
                !fields.CONSENTIMIENTO ||
                fields.CONSENTIMIENTO?.VALOR === 'N'
              ) {
                return 'Debe de aceptar las condiciones.'
              }
              return true
            },
          })}
        />

        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            id="consentimiento1"
            onChange={(event) => handleChange(event)}
            checked={fields.CONSENTIMIENTO?.VALOR === 'S'}
          />
          <label
            className="form-check-label"
            htmlFor="consentimiento1"
          >
            {label || 'Acepta condiciones...'}
          </label>
        </div>

        {errors.CONSENTIMIENTOS && (
          <div className="error">{errors.CONSENTIMIENTOS.message}</div>
        )}
      </div>
    </div>
  )
}
