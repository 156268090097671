import React from 'react'
import useAppNavigation from '../../hooks/useAppNavigation'
import { useFormContext } from 'react-hook-form'
import { useWooCart } from '../../hooks/useWooCart'
import { initialFields } from '../../consts'
import useSessionStorage from '../../hooks/useSessionStorage'
import { Atras } from '../../assets/icons'

export default function NavButtons() {
  const { steps, currentStep, handlePrev, handleNext } = useAppNavigation()
  const { addToCart } = useWooCart()
  const { getValues, setValue, trigger } = useFormContext()
  const [storageData, setStorageData] = useSessionStorage(
    'app_data',
    initialFields
  )
  const [wooCart, setWooCart] = useSessionStorage('woo_cart')

  return (
    <>
      <div className="navButtons">
        {currentStep.id < Object.keys(steps).length - 1 ? (
          <>
            <div>
              {currentStep.id > 0 && (
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  onClick={handlePrev}
                >
                  <Atras />
                </button>
              )}
            </div>

            <button
              className="btn btn-primary"
              type="button"
              onClick={handleNext}
            >
              Siguiente
            </button>
          </>
        ) : (
          <>
            <button
              id="payment-button"
              className="btn btn-primary disabled"
              type="submit"
              onClick={() => {
                trigger().then((response) => {
                  if (response === true) {
                    addToCart(getValues())
                    setStorageData(getValues())
                    setTimeout(() => {
                      window.location.href = `${window.location.protocol}//${window.location.host}/finalizar-compra`
                    }, 1000)
                  }
                })
              }}
            >
              <span
                class="spinner-border"
                role="status"
                aria-hidden="true"
              ></span>
              Confirmando compra...
            </button>

            {/* <div>
              <label>¿Quieres asegurar otra mascota?</label>
              <button
                id="new-pet-button"
                className="btn btn-outline-primary disabled"
                type="submit"
                onClick={() => {
                  trigger().then((response) => {
                    if (response === true) {
                      addToCart(getValues())
                      setTimeout(() => {
                        window.location.href = `${window.location.protocol}//${window.location.host}/finalizar-compra`
                        // Reset values for new pet
                        if (wooCart) setWooCart(null)
                        setValue('MCCRETARITAG', {})
                        setStorageData(getValues())
                      }, 1000)
                    }
                  })
                }}
              >
                Asegurar otra mascota
              </button>
            </div> */}
          </>
        )}
      </div>
    </>
  )
}
