import React, { useEffect, useState } from 'react'
import { useStoreCart } from '../contexts/WooCartContext'
import { FormProvider, useForm } from 'react-hook-form'
import { useFormContent } from '../hooks/useFormContent'
import { useXML } from '../hooks/useXML'
import { initialFields } from '../consts'
import './onboarding.css'

import Nombre from './MCCTARITAG/Nombre'
import DangerousBreed from './MCCTARITAG/DangerousBreed'
import Microchip from './MCCTARITAG/Microchip'
import FecNacimiento from './MCCTARITAG/FecNacimiento'
import Raza from './MCCTARITAG/Raza'
import NavButtons from './navigation/NavButtons'
import NombrePersona from './MCCPERSTAG/NombrePersona'
import Apellido1 from './MCCPERSTAG/Apellido1'
import Apellido2 from './MCCPERSTAG/Apellido2'
import TelefonoParticular from './MCCPERSTAG/TelefonoParticular'
import Nif from './MCCPERSTAG/Nif'
import Email from './MCCPERSTAG/Email'
import CartResume from './CartResume'
import AdultAge from './MCCPERSTAG/AdultAge'
import Consentimiento from './MCCPERSTAG/Consentimiento'

import Provincia from './WOO/Provincia'
import Direccion from './WOO/Direccion'
import CodigoPostal from './WOO/CodigoPostal'
import Poblacion from './WOO/Poblacion'
import useSessionStorage from '../hooks/useSessionStorage'
import Stepper from './navigation/Stepper'

export default function Onboarding() {
  const [storageData] = useSessionStorage('app_data')
  const methods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: storageData ? storageData : initialFields,
  })

  const cart = useStoreCart()

  const [content, setContent] = useState(null)
  const { getContent } = useFormContent()

  const onSubmit = () => {
    console.log('onSubmit', methods.getValues())
  }

  useEffect(() => {
    const { unsubscribe } = methods.watch(() => {
      const { jsonToXML } = useXML()
      const fieldsXML = jsonToXML(methods.getValues())
      console.log(fieldsXML)
    })
    return () => unsubscribe()
  }, [methods.watch])

  useEffect(() => {
    getContent().then((respose) => setContent(respose['/onboarding']))
  }, [])

  return (
    <>
      <FormProvider {...methods}>
        <Stepper />
        <form
          id="onboarding"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          {cart.currentStep?.id === 0 && (
            <div className="container row inner odd">
              <div className="col col-12 col-md-6 step-content">
                <h3>{content?.step_1?.title}</h3>
                <h4>{content?.step_1?.subtitle}</h4>
                <div
                  dangerouslySetInnerHTML={{ __html: content?.step_1?.content }}
                ></div>
              </div>
              <div className="col col-12 col-md-6 animated">
                <Nombre />
                <DangerousBreed />
                <NavButtons />
              </div>
            </div>
          )}

          {cart.currentStep?.id === 1 && (
            <div className="container row inner">
              <div className="col col-12 col-md-6 step-content">
                <h3>
                  {content?.step_2?.title}
                  &nbsp;
                  <strong>{methods.getValues('MCCTARITAG.Nombre')}</strong>
                </h3>
                <h4>{content?.step_2?.subtitle}</h4>
                <div
                  dangerouslySetInnerHTML={{ __html: content?.step_2?.content }}
                ></div>
              </div>
              <div className="col col-12 col-md-6 animated">
                <Microchip />
                <FecNacimiento title="Fecha de nacimiento" />
                <Raza />
                <NavButtons />
              </div>
            </div>
          )}

          {cart.currentStep?.id === 2 && (
            <div className="container row inner odd">
              <div className="col col-12 col-md-6 step-content">
                <h3>{content?.step_3?.title}</h3>
                <h4>{content?.step_3?.subtitle}</h4>
                <div
                  dangerouslySetInnerHTML={{ __html: content?.step_3?.content }}
                ></div>
              </div>
              <div className="col col-12 col-md-6 animated">
                <NombrePersona />
                <div className="row">
                  <div className="col col-12 col-md-6">
                    <Apellido1 />
                  </div>
                  <div className="col col-12 col-md-6">
                    <Apellido2 />
                  </div>
                  <div className="col col-12 col-md-6">
                    <Nif />
                  </div>
                  <div className="col col-12 col-md-6">
                    <TelefonoParticular />
                  </div>
                  <div className="col col-12 col-md-6">
                    <Email />
                  </div>
                  <div className="col col-12 col-md-6">
                    <AdultAge />
                  </div>
                </div>
                <CodigoPostal />
                <Direccion />
                <Provincia />
                <Poblacion />
                <Consentimiento />
                <NavButtons />
              </div>
            </div>
          )}

          {cart.currentStep?.id === 3 && (
            <div className="container row inner">
              <div className="col col-12 col-md-6 step-content">
                <h3>{content?.step_4?.title}</h3>
                <h4>{content?.step_4?.subtitle}</h4>
                <div
                  dangerouslySetInnerHTML={{ __html: content?.step_4?.content }}
                ></div>
              </div>
              <div className="col col-12 col-md-6 animated">
                <CartResume />
                <NavButtons />
              </div>
            </div>
          )}
        </form>
      </FormProvider>
    </>
  )
}
