import React from 'react'
import { useFormContext } from 'react-hook-form'
import { initialFields, provincias } from '../../consts'
import useSessionStorage from '../../hooks/useSessionStorage'

export default function Provincia({ title, label, required = true }) {
  const {
    register,
    getValues,
    formState: { errors },
  } = useFormContext()

  const [storageData, setStorageData] = useSessionStorage(
    'app_data',
    initialFields
  )

  const handleChange = () => {
    setStorageData(getValues())
  }

  return (
    <div className="form-field">
      {title && <span>{title}</span>}
      <div className="form-group">
        <label htmlFor="WOO.billing_state">{label || 'Provincia'}</label>
        <select
          className={`form-control form-select${
            errors.WOO?.billing_state ? ' hasError' : ''
          }`}
          id="WOO.billing_state"
          defaultValue={''}
          {...register('WOO.billing_state', {
            onChange: handleChange,
            required:
              required === true ? 'La provincia es obligatoria.' : false,
            pattern: {
              value: /[A-Z]/,
              message: 'Has introducido caracteres no permitidos.',
            },
            maxLength: {
              value: 2,
              message: 'La provincia es demasiado larga.',
            },
          })}
        >
          <option value="">{label || 'Provincia'}</option>
          {Object.entries(provincias).map(([key, value]) => {
            return (
              <option
                key={key}
                value={key}
              >
                {value}
              </option>
            )
          })}
        </select>

        {errors.WOO?.billing_state && (
          <div className="error">{errors.WOO?.billing_state.message}</div>
        )}
      </div>
    </div>
  )
}
