import React, { useEffect, useState } from 'react'
import { useWooCart } from '../hooks/useWooCart'

export default function WooCheckout() {
  const [cart, setCart] = useState(null)
  const { getCart } = useWooCart()

  const getRetarificarPYS = async (objData) => {
    // Call API PYS -> Retarificar
    const data = {
      action: 'get_retarificar_pys',
      account_number: objData.account_number,
    }

    const response = await fetch(
      `${window.location.protocol}//${window.location.host}/wp-admin/admin-ajax.php`,
      {
        method: 'post',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: new URLSearchParams(data).toString(),
      }
    ).then((response) => response.json())
    if (response) {
      console.log('getRetarificarPYS', response)
      return response
    }
  }

  const getContratarPYS = async (objData) => {
    // Call API PYS -> Retarificar
    const data = {
      action: 'get_contratar_pys',
    }

    const response = await fetch(
      `${window.location.protocol}//${window.location.host}/wp-admin/admin-ajax.php`,
      {
        method: 'post',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: new URLSearchParams(data).toString(),
      }
    ).then((response) => response.json())
    if (response) {
      console.log('getContratarPYS', response)
      return response
    }
  }

  useEffect(() => {
    getCart().then((response) => {
      setCart(response)
      Object.entries(response.woocart).map(([index, product]) => {
        console.log(product.app_data)
      })
    })

    document
      .getElementById('form-checkout')
      .addEventListener('submit', function (e) {
        // e.preventDefault()
        document.getElementById('place_order').innerHTML =
          '<span class="spinner-border" role="status" aria-hidden="true"></span>Contrata tu seguro'
      })
    /** 
    document
      .getElementById('form-checkout')
      .addEventListener('submit', function (e) {
        e.preventDefault()

        const accountNumber = document.getElementById('account_number').value
        if (accountNumber.length > 0) {
          getRetarificarPYS({
            account_number: accountNumber,
          }).then((response) => {
            Object.entries(response).map(([index, product]) => {
              console.log(product.app_data)
            })

            getContratarPYS().then((response) => {
              console.log(response)
              Object.entries(response).map(([index, product]) => {
                console.log(product.app_data)
              })
            })
          })
        }
      })
*/
    console.log('useEffect')
  }, [setCart])
}
