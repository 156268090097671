import React from 'react'
import useAppNavigation from '../../hooks/useAppNavigation'
import PetCards from './PetCards'

export default function Stepper() {
  useAppNavigation()

  return (
    <div id="stepper">
      <PetCards />
    </div>
  )
}
