import React from 'react'
import useAppNavigation from '../../hooks/useAppNavigation'
import { useFormContext } from 'react-hook-form'
import { useWooCart } from '../../hooks/useWooCart'
import { initialFields } from '../../consts'
import useSessionStorage from '../../hooks/useSessionStorage'
import { Atras } from '../../assets/icons'

export default function NavButtons() {
  const { steps, currentStep, handlePrev, handleNext } = useAppNavigation()
  const { addToCart } = useWooCart()
  const { getValues, setValue } = useFormContext()
  const [storageData, setStorageData] = useSessionStorage(
    'app_data',
    initialFields
  )
  const [wooCart, setWooCart] = useSessionStorage('woo_cart')

  return (
    <>
      <div className="navButtons container inner">
        {currentStep.id > 0 ? (
          <button
            type="button"
            className="btn btn-outline-primary"
            onClick={handlePrev}
          >
            <Atras />
          </button>
        ) : (
          <div />
        )}

        {currentStep.id < Object.keys(steps).length - 1 ? (
          <button
            className="btn btn-primary"
            type="button"
            onClick={handleNext}
          >
            Siguiente
          </button>
        ) : (
          <div
            id="call2action"
            className="text-center"
          >
            <label>¿Quieres asegurar otra mascota?</label>
            <button
              className="btn btn-outline-primary"
              type="submit"
              onClick={() => {
                addToCart(getValues())
                window.location.href = `${window.location.protocol}//${window.location.host}/onboarding`

                //Reinicializa valores para otra mascota
                if (wooCart) setWooCart(null)
                setValue('MCCTARITAG', {})
                setStorageData(getValues())
              }}
            >
              Asegurar otra mascota
            </button>
            <button
              className="btn btn-primary"
              type="submit"
              onClick={() => {
                addToCart(getValues())
                setStorageData(getValues())
                window.location.href = `${window.location.protocol}//${window.location.host}/finalizar-compra`
              }}
            >
              CHECKOUT
            </button>
          </div>
        )}
      </div>
    </>
  )
}
