/* eslint-disable react/prop-types */
import React from 'react'
import { useFormContext } from 'react-hook-form'
import useSessionStorage from '../../hooks/useSessionStorage'
import { initialFields } from '../../consts'

export default function AdultAge({ title, label, required = true }) {
  const {
    register,
    getValues,
    formState: { errors },
  } = useFormContext()

  function isAdult(data) {
    const now = new Date()
    const birthdate = new Date(data)
    let age = now.getFullYear() - birthdate.getFullYear()
    const m = now.getMonth() - birthdate.getMonth()
    if (m < 0 || (m === 0 && now.getDate() < birthdate.getDate())) {
      age--
    }
    return age > 18
  }

  const [storageData, setStorageData] = useSessionStorage(
    'app_data',
    initialFields
  )

  const handleChange = () => {
    setStorageData(getValues())
  }

  return (
    <div className="form-field">
      {title && <span>{title}</span>}

      <div className="form-group">
        <label htmlFor="adultAge">{label || 'Fecha de nacimiento'}</label>
        <input
          id="adultAge"
          type="date"
          className={`form-control${errors.adultAge ? ' hasError' : ''}`}
          {...register('adultAge', {
            onChange: handleChange,
            required:
              required === true
                ? 'La fecha de nacimiento es obigatoria.'
                : false,
            validate: (value) => {
              if (isAdult(value) === false) {
                return 'Para continuar debes de ser mayor de edad.'
              }
              return true
            },
          })}
        />
        {errors.adultAge && (
          <div className="error">{errors.adultAge.message}</div>
        )}
      </div>
    </div>
  )
}
