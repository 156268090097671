import React from 'react'
import { useFormContext } from 'react-hook-form'
import useSessionStorage from '../../hooks/useSessionStorage'
import { initialFields } from '../../consts'
import { Information } from '../../assets/icons'

export default function Email({ label, title, required = true }) {
  const {
    register,
    getValues,
    formState: { errors },
  } = useFormContext()

  const [storageData, setStorageData] = useSessionStorage(
    'app_data',
    initialFields
  )

  const handleChange = () => {
    setStorageData(getValues())
  }

  const handleHelp = () => {
    const elem = document.getElementById('info-email')
    if (elem.classList.contains('d-none')) elem.classList.remove('d-none')
    else elem.classList.add('d-none')
  }

  return (
    <div className="form-field">
      {title && <span>{title}</span>}
      <div className="form-group">
        <label
          htmlFor="MCCPERSTAG.TOMADOR.Email"
          // onClick={handleHelp}
        >
          {label || 'Email'}
          {/* {label || (
            <>
              <Information /> Email
            </>
          )} */}
        </label>
        <input
          type="email"
          id="MCCPERSTAG.TOMADOR.Email"
          className={`form-control${
            errors.MCCPERSTAG?.TOMADOR?.Email ? ' hasError' : ''
          }`}
          placeholder={label || 'Email'}
          {...register('MCCPERSTAG.TOMADOR.Email', {
            onChange: handleChange,
            required: required === true ? 'El email es obligatorio.' : false,
            pattern: {
              value:
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>() [\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              message: 'El formato del email no es válido.',
            },
            maxLength: {
              value: 50,
              message: 'El email es demasiado largo.',
            },
          })}
        />
        {errors.MCCPERSTAG?.TOMADOR?.Email && (
          <div className="error">{errors.MCCPERSTAG.TOMADOR.Email.message}</div>
        )}
      </div>
      {/* <div
        id="info-email"
        className="info-panel d-none"
      >
        <Information />
        Una vez contratada la póliza, te enviaremos un correo electrónico con la
        documentación del seguro.
      </div> */}
    </div>
  )
}
