export default function useCSV() {
  function parseCSV(text) {
    const lines = text.split(/\r\n|\r|\n/)
    const headers = lines[1].split(',')
    return lines.map((line) => {
      const obj = {}
      const values = line.split(',')
      for (let j = 0; j < headers.length; j++) {
        obj[headers[j]] = values[j]
      }
      return obj
    })
  }

  async function readFile(name) {
    return fetch(name)
      .then((response) => response.text())
      .then((text) => parseCSV(text))
  }

  return { readFile }
}
