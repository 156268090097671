import React, { useEffect } from 'react'
import { Huella } from '../../assets/icons'
import { Toaster, toast } from 'sonner'
import { useWooCart } from '../../hooks/useWooCart'

// https://sonner.emilkowal.ski/

export default function PetCards() {
  const { getCart } = useWooCart()

  useEffect(() => {
    getCart().then((cart) => {
      if (Object.keys(cart.woocart).length > 0) {
        Object.values(cart.woocart).map((product, index) => {
          const pet = JSON.parse(
            product.app_data.MCCTARITAG.replace(new RegExp('\\\\', 'g'), '')
          )
          return toast.success(
            (t) => (
              <div
                id={index}
                className="pet"
              >
                <p>Mascota en el carrito:</p>
                <div className="h4">{pet.Nombre}</div>
                <div>
                  <strong>Raza: </strong>
                  {pet.Raza}
                </div>
                <div>
                  <strong>Nº Microchip: </strong>
                  {pet.Microchip}
                </div>
                <div>
                  <strong>Edad: </strong>
                  {calculateAge(pet.FecNacimiento)}
                </div>
              </div>
            ),
            {
              className: 'toast-pet',
              duration: 5000,
            }
          )
        })
      }
    })
  }, [])

  return (
    <Toaster
      closeButton
      position="bottom-right"
      icons={{
        success: <Huella />,
      }}
    />
  )
}

export function calculateAge(date) {
  if (!date) return

  const inputDate = [
    date.substring(0, 4),
    date.substring(4, 6),
    date.substring(6, 8),
  ]

  var today = new Date()
  var year = today.getFullYear()
  var month = today.getMonth() + 1
  var day = today.getDate()
  var yy = parseInt(inputDate[0])
  var mm = parseInt(inputDate[1])
  var dd = parseInt(inputDate[2])

  var years, months
  // months
  months = month - mm
  if (day < dd) {
    months = months - 1
  }
  // years
  years = year - yy
  if (month * 100 + day < mm * 100 + dd) {
    years = years - 1
    months = months + 12
  }

  return `${years}${years > 1 ? ' años' : ' año'} y ${months}${
    months > 1 ? ' meses' : ' mes'
  }`
}
