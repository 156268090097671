import { useState } from 'react'
import { useDispatchCart, useStoreCart } from '../contexts/WooCartContext'
import { typesCart } from '../store/storeCartReducer'

export default function useSessionStorage(key, initialValue) {
  const cart = useStoreCart
  const dispatchCart = useDispatchCart()

  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.sessionStorage.getItem(key)
      return item ? JSON.parse(item) : initialValue
    } catch (error) {
      return initialValue
    }
  })

  const setValue = (value) => {
    try {
      setStoredValue(value)
      dispatchCart({
        type: typesCart.update,
        payload: {
          ...cart,
          [key]: value,
        },
      })
      window.sessionStorage.setItem(key, JSON.stringify(value))
    } catch (error) {
      console.error(error)
    }
  }

  return [storedValue, setValue]
}
