import React, { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { initialFields, PostalCodeProvincias } from '../../consts'
import useSessionStorage from '../../hooks/useSessionStorage'

export default function CodPostal({ title, label, required = true }) {
  const {
    register,
    getValues,
    setValue,
    clearErrors,
    formState: { errors },
  } = useFormContext()

  const [municipios, setMunicipios] = useState()
  const [filtered, setFiltered] = useState([])

  const [storageData, setStorageData] = useSessionStorage(
    'app_data',
    initialFields
  )

  const handleChange = (event) => {
    if (event.target.value.length === 5) {
      getLocalidadPYS(event.target.value)
    } else {
      setFiltered([])
    }

    if (event.target.value.length > 5) {
      setValue(
        'MCCPERSTAG.TOMADOR.CodPostal',
        event.target.value.substring(0, 5)
      )
    }
    if (event.target.value.length === 5) {
      setStorageData(getValues())
    }
    // console.log('handleChange', filtered)
  }

  const getLocalidadPYS = async (codPostal) => {
    const data = { action: 'get_localidades_pys', codPostal }
    const response = await fetch(
      `${window.location.protocol}//${window.location.host}/wp-admin/admin-ajax.php`,
      {
        method: 'post',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: new URLSearchParams(data).toString(),
      }
    ).then((response) => response.json())
    // console.log(response)

    if (response?.Localidades?.LOCALIDAD != null) {
      const localidadesPYS =
        Object.keys(response?.Localidades.LOCALIDAD).length === 1
          ? [{ ...response?.Localidades.LOCALIDAD }]
          : response?.Localidades.LOCALIDAD
      // console.log(localidadesPYS)

      const options = localidadesPYS.map(({ Localidad }) => {
        return {
          cod_postal: codPostal,
          provincia: codPostal.slice(0, 2),
          poblacion: Localidad,
        }
      })
      setMunicipios(options)
      setFiltered(options)
    }
  }

  return (
    <div className="form-field">
      {title && <span>{title}</span>}
      <div className="form-group">
        <label htmlFor="MCCPERSTAG.TOMADOR.CodPostal">
          {label || 'Código postal'}
        </label>
        <input
          className={`form-control${
            errors.MCCPERSTAG?.TOMADOR?.CodPostal ? ' hasError' : ''
          }`}
          type="text"
          id="MCCPERSTAG.TOMADOR.CodPostal"
          placeholder={label || 'Código postal'}
          {...register('MCCPERSTAG.TOMADOR.CodPostal', {
            onChange: handleChange,
            required: {
              value: true,
              message: 'El código postal es obligatorio.',
            },
            pattern: {
              value: /[0-9]{5}/,
              message: 'Error en el formato de código postal.',
            },
            maxLength: {
              value: 5,
              message: 'Error en el formato de código postal.',
            },
          })}
        />

        {errors.MCCPERSTAG?.TOMADOR?.CodPostal && (
          <div className="error">
            {errors.MCCPERSTAG.TOMADOR.CodPostal.message}
          </div>
        )}

        {filtered && Object.keys(filtered).length > 0 && (
          <div
            id="municipios-popup"
            className="d-flex py-2"
          >
            <div
              className="close"
              onClick={() => setFiltered([])}
            >
              🗙
            </div>

            {}
            {filtered &&
              filtered.map((localidad) => (
                <div
                  className="municipio"
                  onClick={() => {
                    //Set form values and storage
                    // setValue(
                    //   'WOO.billing_postcode',
                    //   `${localidad.cod_postal.padStart(5, '0')}`
                    // )
                    setValue(
                      'MCCPERSTAG.TOMADOR.CodPostal',
                      `${localidad.cod_postal.padStart(5, '0')}`
                    )
                    // setValue('WOO.billing_city', localidad.poblacion)
                    setValue(
                      'MCCPERSTAG.TOMADOR.Localidad',
                      localidad.poblacion
                    )

                    let prov = PostalCodeProvincias.filter((provincia) =>
                      localidad.cod_postal
                        .padStart(5, '0')
                        .startsWith(provincia.CPRO)
                    ).shift()

                    setValue('WOO.billing_state', prov.woo)
                    setStorageData(getValues())

                    //Clean form errors and reset filters
                    clearErrors('MCCPERSTAG.TOMADOR.CodPostal')
                    clearErrors('MCCPERSTAG.TOMADOR.Localidad')
                    setFiltered([])
                  }}
                >
                  {localidad.poblacion}
                </div>
              ))}
          </div>
        )}
      </div>
    </div>
  )
}
