import React from 'react'
import { useFormContext } from 'react-hook-form'
import useSessionStorage from '../../hooks/useSessionStorage'
import { initialFields } from '../../consts'
import { Information } from '../../assets/icons'

export default function FecEfecto({ title, label, required = true }) {
  const {
    register,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext()

  function threeMonths(data) {
    const now = new Date()
    now.setHours(0, 0, 0, 0)
    const limitDate = new Date()
    limitDate.setMonth(limitDate.getMonth() + 3)
    const initDate = new Date(data)

    return initDate >= now && initDate < limitDate
  }

  const [storageData, setStorageData] = useSessionStorage(
    'app_data',
    initialFields
  )

  const handleChange = () => {
    setValue(
      'MCCRETARITAG.FecEfecto',
      getValues('FecEfecto').replaceAll('-', '')
    )
    setStorageData(getValues())
  }

  const handleHelp = () => {
    const elem = document.getElementById('info-FecEfecto')
    if (elem.classList.contains('d-none')) elem.classList.remove('d-none')
    else elem.classList.add('d-none')
  }

  return (
    <div className="form-field">
      {title && <span>{title}</span>}

      <div className="form-group">
        <label
          htmlFor="FecEfecto"
          onClick={handleHelp}
        >
          {label || 'Fecha de inicio'}
          &nbsp;
          <Information />
        </label>

        <input
          id="FecEfecto"
          type="date"
          className={`form-control${errors.FecEfecto ? ' hasError' : ''}`}
          {...register('FecEfecto', {
            onChange: handleChange,
            required:
              required === true
                ? 'La fecha de inicio de tu póliza es obligatoria.'
                : false,
            validate: (value) => {
              if (threeMonths(value) === false) {
                return 'Tu seguro debe tomar efecto dentro de los próximos tres meses.'
              }
              return true
            },
          })}
        />
        {errors.FecEfecto && (
          <div className="error">{errors.FecEfecto.message}</div>
        )}
      </div>
      <div
        id="info-FecEfecto"
        className="info-panel d-none"
      >
        <span onClick={handleHelp}>
          <Information />
        </span>
        Indica la fecha en la que quieres que tu póliza tome efecto.<br></br>Tu
        seguro debe tomar efecto dentro de los&nbsp;
        <strong>próximos tres meses</strong>.
      </div>
    </div>
  )
}
