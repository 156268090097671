import { initialCart } from '../consts'

const typesCart = {
  initialize: 'initialize - cart',
  update: 'update - cart'
}

const storeCartReducer = (state, action) => {
  switch (action.type) {
    case typesCart.initialize:
      return initialCart

    case typesCart.update:
      return { ...state, ...action.payload }

    default:
      return state
  }
}

export { initialCart, typesCart }
export default storeCartReducer
