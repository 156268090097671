export function useWooProduct() {
  const getProductContent = async (data) => {
    if (data?.action === undefined)
      data = { ...data, action: 'get_woo_products' }

    const response = await fetch(
      `${window.location.protocol}//${window.location.host}/wp-admin/admin-ajax.php`,
      {
        method: 'post',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: new URLSearchParams(data).toString(),
      }
    )
    if (!response.ok) {
      throw new Error('Failed to fetch form content.')
    }

    const json = await response.json()
    return json
  }

  return { getProductContent }
}
