import React from 'react'
import { useFormContext } from 'react-hook-form'
import useSessionStorage from '../../hooks/useSessionStorage'
import { initialFields, TipoVia } from '../../consts'

export default function Direccion({ title, label, required = true }) {
  const {
    register,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext()

  const [storageData, setStorageData] = useSessionStorage(
    'app_data',
    initialFields
  )

  const handleChange = () => {
    const fields = getValues()
    const street = document.getElementById('street_type')
    const street_value = street.options[street.selectedIndex].text
    const address = [street_value, fields.address, fields.street_number]
    setValue('WOO.billing_address_1', address.join(' '))
    setStorageData(getValues())
  }

  return (
    <div className="form-field">
      {title && <span>{title}</span>}

      <div className="row">
        <div className="form-group col-md-4">
          <label htmlFor="street_type">Tipo de vía</label>
          <select
            id="street_type"
            className={`form-control form-select${
              errors.WOO?.billing_address_1 ? ' hasError' : ''
            }`}
            defaultValue={''}
            {...register('street_type', {
              onChange: handleChange,
              required:
                required === true ? 'El tipo de calle es obligatorio.' : false,
              pattern: {
                value: /[A-Z]{2}/,
                message: 'Has introducido caracteres no permitidos.',
              },
              maxLength: {
                value: 2,
                message: 'El tipo de calle es demasiado largo.',
              },
            })}
          >
            <option value="">Tipo</option>
            {TipoVia.map((tipo, index) => {
              return (
                <option
                  key={index}
                  value={tipo.cod}
                >
                  {tipo.name}
                </option>
              )
            })}
          </select>
        </div>

        <div className="form-group col-md-6">
          <input
            type="hidden"
            id="WOO.billing_address_1"
            {...register('WOO.billing_address_1', {
              onChange: handleChange,
              required:
                required === true ? 'La dirección es obligatoria.' : false,
            })}
          />

          <label htmlFor="address">{label || 'Dirección'}</label>
          <input
            className={`form-control${errors?.address ? ' hasError' : ''}`}
            type="text"
            id="address"
            placeholder={label || 'Dirección'}
            {...register('address', {
              onChange: handleChange,
              required:
                required === true ? 'La dirección es obligatoria.' : false,
              pattern: {
                value: /^[a-zA-ZÀ-ÖØ-öø-ÿ 0-9º º/\\.,-]*$/i,
                message: 'Has introducido caracteres no permitidos.',
              },
              minLength: {
                value: 5,
                message: 'La dirección es demasiado corta.',
              },
              maxLength: {
                value: 150,
                message: 'La dirección es demasiado larga.',
              },
            })}
          />
        </div>

        <div className="form-group col-md-2">
          <label htmlFor="street_number">Nº</label>
          <input
            id="street_number"
            className={`form-control${
              errors?.street_number ? ' hasError' : ''
            }`}
            type="text"
            {...register('street_number', {
              onChange: handleChange,
              required: required === true ? 'El número es obligatorio.' : false,
              pattern: {
                value: /^[0-9-]*$/i,
                message: 'Has introducido caracteres no permitidos.',
              },
              maxLength: {
                value: 10,
                message: 'El número es demasiado largo.',
              },
            })}
          />
        </div>

        {(errors?.street_type || errors?.address || errors?.street_number) && (
          <div className="error">
            {errors.street_type ? <p>{errors.street_type.message}</p> : ''}
            {errors.address ? <p>{errors.address.message}</p> : ''}
            {errors.street_number ? <p>{errors.street_number.message}</p> : ''}
          </div>
        )}
      </div>
    </div>
  )
}
