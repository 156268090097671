import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { initialFields } from '../consts'

import Nombre from './MCCTARITAG/Nombre'
import DangerousBreed from './MCCTARITAG/DangerousBreed'

export default function Home() {
  const methods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: initialFields,
  })

  const onSubmit = (data) => {
    // Add to sessionStorage
    window.sessionStorage.setItem(
      'app_data',
      JSON.stringify(methods.getValues(), null, 2)
    )
    window.location.href = `${window.location.protocol}//${window.location.host}/onboarding/mascota`
    console.log('onSubmit', data)
  }

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <h2 className="h2">
            Contrata <span>Tu Seguro</span> ahora
          </h2>
          <Nombre />
          <DangerousBreed />
          <div id="navigation_buttons">
            <button
              type="submit"
              className="btn btn-primary"
            >
              Contratar ahora
            </button>
          </div>
        </form>
      </FormProvider>
    </>
  )
}
